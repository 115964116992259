<template>

    <div class="px-5 sm:px-52 mx-auto my-20">
        <div style="font-size: 20px">
            <div>
                <h2 class="font-extrabold color-purple text-3xl RobotoMedium" >Educación Financiera para Menores</h2>
                <div class="liner-yellow"></div>
            </div>
        </div>

        <div class="mt-10">
            <div>
                <p class="font-extrabold color-grey text-1xl RobotoMedium">¿Qué es la educación financiera?</p>
                <p class="color-grey text-info">La educación financiera comprende un conjunto de prácticas y conocimientos que nos ayuda a entender cómo funciona el dinero y así poder realizar una gestión correcta de nuestras finanzas.</p>
            </div>
        </div>

        <div class="mt-10">
            <div>
                <p class="font-extrabold color-grey text-1xl RobotoMedium">¿Por qué es importante?</p>
                <p class="color-grey text-info">La educación financiera nos proporciona la posibilidad de lograr una estabilidad económica. Es decir, poder prevenir y evitar malas situaciones económicas, como los endeudamientos. Para esto, es importante conocer los conceptos básicos y saber ponerlos en práctica.</p>
            </div>
        </div>

        <div class="relative flex py-5 items-center">
            <div class="flex-grow border-t border-gray-400"></div>
        </div>

        <div>
            <div class="mx-3">
                <li type="disc"><b class="font-extrabold color-grey text-1xl RobotoMedium">Ingresos:</b> Los ingresos suelen estar compuestos por un salario que se parcibe al pertenecer a un trabajo, también pueden ser
                ganancias por la venta de algún producto o por ingresos derivados de una actividad a realizada por cuenta propia, entre otros.</li>
            </div>
        </div>

        <div class="relative flex py-5 items-center">
            <div class="flex-grow border-t border-gray-400"></div>
        </div>

        <div>
            <div class="mx-3">
                <li type="disc"><b class="font-extrabold color-grey text-1xl RobotoMedium">Gastos:</b> Un gasto es un egreso o, en términos coloquiales, una salida de dinero que una empresa o persona paga por la compra de un producto, un bien o un servicio. Es indispensable que nuestros gastos estén controlados y se encuentren por debajo de muestros ingresos para poder ahorrar.</li>
            </div>
        </div>

        <div class="relative flex py-5 items-center">
            <div class="flex-grow border-t border-gray-400"></div>
        </div>

        <div>
            <div class="mx-3">
                <li type="disc"><b class="font-extrabold color-grey text-1xl RobotoMedium">Inversión:</b> Una inversión es una cantidad limitada de dinero que se pone a disposicion de terceros, de una empresa o de un conjunto de acciones, El ahorro nos ayuda a poder invertir en activos que nos generen rentabilidad y nos ayude a superar periodos de crisis.</li>
            </div>
        </div>

        <div class="relative flex py-5 items-center">
            <div class="flex-grow border-t border-gray-400"></div>
        </div>

        <div class="mt-10">
            <div>
                <p class="font-extrabold color-grey text-1xl RobotoMedium">¿Cómo enseñar educación financiera a menores de edad?</p>
                <p class="color-grey text-info">La cultura financiera para menores de edad debe iniciarse en casa, ya que gracia a la comunicación y la práctica de esta fomentamos desde jóvenes una vida de finanzas sanas. De esta manera heramos que poco a poco lo pongan en práctica ellos mismos de manera natural. Entender la diferencia entre valor, precio y la diferencia entre necesidad y gasto innecesario, les ayudará a entender que no todo cuesta dinero y tendrán herramientas para realizar compras mejor pensadas.</p>
            </div>
        </div>

        <div class="mt-10">
            <div>
                <p class="font-extrabold color-grey text-1xl RobotoMedium">Consejos para que los menores de edad puedan aprender sobre la educación financiera:</p>
            </div>
        </div>

        <div class="relative flex py-5 items-center">
            <div class="flex-grow border-t border-gray-400"></div>
        </div>

        <div>
            <div class="mx-3">
                <li type="disc"><b class="font-extrabold color-grey text-1xl RobotoMedium">Fomentar el hábito del ahorro:</b> El ahorro les enseñará a tener objetivos de compra, ponerse objetivos de ahorro es muy importante. Se puede empezar con regalarles un monedero, billetera o alcancia que les permita ahorrar para comprarse ese objetivo que tienen fijado.</li>
            </div>
        </div>

        <div class="relative flex py-5 items-center">
            <div class="flex-grow border-t border-gray-400"></div>
        </div>

        <div>
            <div class="mx-3">
                <li type="disc"><b class="font-extrabold color-grey text-1xl RobotoMedium">Proporcionales una paga para que aprendan a administrarla:</b> Una forma de apoyarlos es proporcionarles una paga fija semanal, con esto aprenderá que el dinero es limitado, que debe priorizar y no se puede comprar todo lo que quiera y que ahorrar requiere un esfuerzo.</li>
            </div>
        </div>

        <div class="relative flex py-5 items-center">
            <div class="flex-grow border-t border-gray-400"></div>
        </div>

        <div>
            <div class="mx-3">
                <li type="disc"><b class="font-extrabold color-grey text-1xl RobotoMedium">Hazlo simple y divertido:</b> Puede utilizar juegos de mesa o aplicaciones móviles interactivas.</li>
            </div>
        </div>

        <div class="relative flex py-5 items-center">
            <div class="flex-grow border-t border-gray-400"></div>
        </div>

        <div>
            <div class="mx-3">
                <li type="disc"><b class="font-extrabold color-grey text-1xl RobotoMedium">Cómprale libros financieros ilustrados:</b> Hay libros animados que, a travéz de sus historias, dejan buenas enseñanzas en referencia al ahorro.</li>
            </div>
        </div>

        <div class="relative flex py-5 items-center">
            <div class="flex-grow border-t border-gray-400"></div>
        </div>

        <div>
            <div class="mx-3">
                <li type="disc"><b class="font-extrabold color-grey text-1xl RobotoMedium">Ábrele una cuenta de ahorros:</b> Una de las desventajas de tener dinero en efectivo es que pueden usarlo de forma rapida e impulsiva. En cambio, el hecho de contar con una cuenta de ahorro no les permite acceder e ella de manera directa, por lo cual van a pensar un poco más antes de gastar.</li>
            </div>
        </div>

        <div class="relative flex py-5 items-center">
            <div class="flex-grow border-t border-gray-400"></div>
        </div>

        <div>
            <div>
                <h3 class="font-extrabold color-purple text-2xl RobotoMedium">Fuentes:</h3>
            </div>
        </div>

        <div class="relative flex py-5 items-center">
            <div class="flex-grow border-t border-gray-400"></div>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-1">
            <p class="color-grey text-info">
                <a class="hover:text-blue-700" href="https://www.oecd.org/finance/financial-education/oecdpisafinancialliteracyassessment.htm" target="_blank">OECD PISA FINANCIAL LITERACY ASSESSMENT OF STUDENT</a><br>
                <a class="hover:text-blue-700" href="https://www.bbva.com/es/ar/sostenibilidad/mas-de-5000-jovenes-de-entre-16-y-18-anos-aprenden-finanzas-junto-a-bbva-y-junior-achievement/" target="_blank">CENTRO PARA LA EDUCACIÓN Y CAPACIDADES FINANCIERAS</a><br>
                <a class="hover:text-blue-700" href="https://www.ft.com/content/7efbd339-b02c-4c3b-a707-f9ee1f433f05" target="_blank">EDUFIN SUMMIT</a><br>
                <a class="hover:text-blue-700" href="https://www.bbva.mx/educacion-financiera/ahorro/ahorro-para-ninos.html" target="_blank">BBVA SEMANA DE EDUCACIÓN FINANCIERA</a><br>
                <a class="hover:text-blue-700" href="https://finhealthnetwork.org/research/workplace-financial-health-innovation-rolling-out-an-earned-wage-access-program-for-your-employees/" target="_blank">FINANCIAL HEALTH NETWORK</a>
            </p>
        </div>

        <div class="relative flex py-5 items-center mb-10">
            <div class="flex-grow border-t border-gray-400"></div>
        </div>
        
    </div>

</template>

<script>
export default {
  data() {
      return {
        };
      },
  name: 'FinancialEducationForMinors',
  methods:{
  }
}

</script>

<style>
#button_popups {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #feb72b !important;
  color: #fff !important;
  font-weight:bold !important;
  width: 250px !important;
  height: 40px !important;
  padding-top: 0% !important;
  font-size:16px !important;
}
.liner-yellow{
   width: 100px;
    background: #f8c603;
    height: 5px; 
}
</style>

<style scoped>
.liner-yellow {
  width: 100px;
  background: #f8c603;
  height: 5px;
}
.text-info{
  font-family: "RobotoRegular";
}
.color-purple {
  color: #810042;
}
.color-grey {
  color: #726659;
}
</style>