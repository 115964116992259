<template>

    <Menu :list="menuList"/>

    <FinancialEducationForMinors/>

    <Footer/>

</template>


<script>

import Menu from '../components/Menu.vue';
import Footer from '@/components/Footer3.vue';
import Loading from '@/components/Loading/VueLoading.vue'
import FinancialEducationForMinors from '@/components/FinancialEducationForMinors.vue';

import menuOptions  from "@/helpers/menu.js";
import { ref, onBeforeMount } from 'vue';

export default {
  name: 'SavingsEnrollmentForMinorsViews',
  setup(){
    let menuList = ref([]);

    onBeforeMount(async () => {
      await getMenuOptionsList();
    });

    const getMenuOptionsList = async () => {
      await menuOptions.getMenuOptions().then(resp => {
        menuList.value = resp.data.FONCABSA;
      }).catch(err => {
        console.log("🚀 ~ file: Home.vue:223 ~ getMenuOptions ~ err", err);
      });
    };

    return {
      menuList
    }
  },
  components: {
    Menu,
    Footer,
    Loading,
    FinancialEducationForMinors
  }
}
</script>